import { useState } from 'react';
import { supabase } from '../../../constants/supabase-client';
import { SUPABASE_API_KEY, SUPABASE_URL } from '../../../constants/env-configs';

const EDGE_FUNCTION_URL = `${SUPABASE_URL}/functions/v1/checkIP`;

export function useDatabase() {
  const [isAllowed, setIsAllowed] = useState(true);
  const [fundsCheckPassed, setFundsCheckPassed] = useState(true);

  const checkIPRateLimit = async () => {
    try {
      const response = await fetch(EDGE_FUNCTION_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${SUPABASE_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: 'Functions' }),
      });

      const data = await response.json();
      setIsAllowed(data.allowed);

      return data.allowed;
    } catch (error) {
      console.error('Error checking IP rate limit:', error);
      setIsAllowed(false);
      return false;
    }
  };

  const checkFundsLimit = async (address: string, newFunds: number) => {
    try {
      const { data, error } = await supabase.functions.invoke('check-funds-limit', {
        body: { address, newFunds }
      })
  
      if (error) throw error
  
      setFundsCheckPassed(true)
      return true
    } catch (error) {
      console.error('Error checking funds limit:', error)
      setFundsCheckPassed(true)
      return true
    }
  }

  const updateFunds = async (address: string, funds: number) => {
    if (!isAllowed || !fundsCheckPassed) {
      console.warn('Prerequisite check failed');
      return;
    }

    console.log('Updating funds:', address, funds);
  
    try {
      const { data, error } = await supabase.functions.invoke('update-funds', {
        body: { address, funds }
      });
  
      if (error) throw error;
  
      return data;
    } catch (error) {
      console.error('Error updating funds:', error);
    }
  };

  return {
    isAllowed,
    fundsCheckPassed,
    updateFunds,
    checkIPRateLimit,
    checkFundsLimit,
  };
}
