import styles from './app.module.scss';
import { BaseError, useAccount } from 'wagmi';
import Logo from '../../../media/logo.png';
import { Button } from '../../../ui/components/button/button';
import { useState, useCallback, useRef, SetStateAction } from 'react';
import { useClaim } from '../forms/claim/hooks/use-claim';
import { Slider } from '../../../ui/components/slider/slider';
import { FundDialog } from '../fund-dialog/fund-dialog';
import Turnstile from 'react-turnstile';
import { CLOUD } from '../../constants/env-configs';
import { useTurnstile } from '../cloudflare/hooks/use-turnstile';

export function App() {
  const {
    toAddress,
    setToAddress,
    amount,
    setAmount,
    fundAccount,
    isPending,
    isConfirming,
    isConfirmed,
    dbError,
    error,
    status,
    reset,
    closeFundDialog,
    isFundDialogOpen,
    setTurnstileToken,
    turnstileToken,
    setStoredHash,
  } = useClaim();
  
  const { address } = useAccount();

  const { siteKey, isVerified, verifyTurnstile, resetTurnstile, onLoad } = useTurnstile();


  const clickHandler = () => {
    fundAccount();
  };

  const turnstileCallback = useCallback((token: string) => {
    setTurnstileToken(token);
  }, [setTurnstileToken]);


  const buttonText = () => {
    return 'Receive Tokens';
  };

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        To import the Sepolia $ANYONE token use the following contract address:
        0x3f7d7e1161b4cbc172517a2957a13814f4a657a2
      </div>
      <div className={styles.header}>
        <div className={styles.logo} onClick={() => window.open('https://www.anyone.io/', '_blank')}>
          <img src={Logo} alt="logo" />
          <div>Anyone</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.card}>
          <div className={styles.title}>Anyone Faucet</div>
          <div className={styles.information}>
          <p>
      The $ANYONE Faucet allows users to receive $ANYONE and Ethereum Sepolia tokens through our Beta Mainnet rewards program to help us test the locking, claim, and distribution protocols.
    </p>
    <p>
      <strong>Claim Options</strong><br />
      Choose the default 100 $ANYONE and claim up to 5 times per hour, or use the slider to claim up to 500 $ANYONE in one go.
    </p>
    <p>
      <strong>Limits</strong><br />
      500 $ANYONE per hour, with a total of 10,000 $ANYONE per address.
      The receiving address must hold at least 100 $ANYONE tokens on mainnet to claim.
    </p>
          </div>

          <div className={styles.amountContainer}>
            <div className={styles.balanceContainer}>
              <div className={styles.balance}>Receiving Address</div>
              <input
                type="text"
                onChange={(e) => {
                  let value = e.target.value.trim();
                  setToAddress(value as `0x${string}`);

                }}
                value={toAddress}
              />
            </div>
          </div>

          <div className={styles.amountContainer}>
            <div className={styles.balanceContainer}>
              <div className={styles.balance}>Amount of Tokens to Receive</div>
              <Slider
                value={amount}
                min={0}
                max={500} // Set the max value according to your requirements
                step={1}
                onChange={(e) => setAmount(parseInt(e.target.value))}
                fullWidth
                contrasted
              />
            </div>
          </div>

          <div className={styles.turnstileContainer}>
            <Turnstile
              sitekey={CLOUD}
              onVerify={turnstileCallback}
              onLoad={onLoad}
            />
          </div>

          <div className={styles.stepsContainer}>
            <Button 
              onClick={clickHandler} 
              className={styles.btn} 
              contrasted 
              fullWidth
              disabled={!turnstileToken}
            >
              {buttonText()}
            </Button>
          </div>
        </div>
      </div>
      <FundDialog
        hide={async () => {
          closeFundDialog();
          setAmount(100);
          resetTurnstile();
          localStorage.removeItem('transactionHash');
          setStoredHash(null);
          reset();
        }}
        visible={isFundDialogOpen}
        error={error as BaseError}
        isConfirmed={isConfirmed}
        isConfirming={isConfirming}
        isPending={isPending}
        dbError={dbError}
      />
    </div>
  );
}
